.card{
    position: absolute;
    width: 607px;
    height: 695px;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) drop-shadow(-6px 0px 10px rgba(49, 49, 49, 0.25));
    border-radius: 20px;
    background-color: white;

    .header{
        background: #084466;
        height:152px;
        border-radius: 20px 20px 0 0;
        display: flex;
        align-items: center;

        .priceContainer{
            width:150px;
            display: flex;
            justify-content: flex-end;
            .price{
                position:relative;
                background: #FFFFFF;
                border-radius: 20px;
                width:102px;
                height:102px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content:space-evenly;

                .amount{
                    color:#084466;
                    font-weight: bold;
                }

                .currencySelection{
                    position: absolute;
                    list-style-type: none;
                    left:0;
                    background-color: white;
                    width:100%;
                    text-align: center;
                    ul{
                        padding:0;
                        cursor: pointer;
                    }
                }
            }
        }
        
        .order{
            color:white;
            text-align: center;
            flex-grow: 1;
        }

        .logo{
            &>img{
                width:70px;
            }
            
        }
    }

}

@media screen and (max-width: 630px),screen and (max-height: 696px) {
    .card{
        width: 100%;
        height: 100%;
        border-radius: 0;
        overflow: scroll;
        .header{
            border-radius: 0;
        }
    }
}
