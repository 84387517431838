.body{
    margin:5% 10% ;
    .label{
        font-style: normal;
        font-weight: 500;
        display: flex;
        align-items: center;
        color: #383838;
    }
    .network{
        border: 1px solid #000000;
        border-radius: 5px;
        cursor: pointer;
        display: flex;
        align-items: center;
        padding:10px;
        margin:10px 0;
        .name{
            flex-grow: 1;
            font-weight: bold;
        }
        svg{
            width: 21px;
            height: 100%;
            padding-right: 10px;
        }
        img{
            height: 35px;
        }
    }
    .backButton{
        position: absolute;
        bottom: 5%;
        left: 50%;
        transform: translateX(-50%);
        cursor: pointer;
    }
}