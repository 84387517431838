.body{
    margin:5% 10% ;
    .expired{
        text-align: center;
        color: red;
    }
    .label{
        font-style: normal;
        font-weight: 500;
        display: flex;
        align-items: center;
        color: #383838;
    }
    .address{
        border: 1px solid #000000;
        border-radius: 5px;
        padding:5px;
        display: flex;
        justify-content: center;
        margin:10px 0;
        align-items: center;
        span{
            flex-grow: 1;
            text-align: center;
            word-break: break-all;
        }
        svg{
            cursor: pointer;
        }
    }
    .copied{
        margin: unset;
        padding: unset;
        text-align: right;
        font-size: 12px;
        color: red;
    }
    .message{
        text-align: center;
    }
    .backButton{
        position: absolute;
        bottom: 5%;
        left: 50%;
        transform: translateX(-50%);
        cursor: pointer;
    }
}
@media screen and (max-width:555px){
    .body{
        .address{
            svg{
                height: 1.5em;
            }
        }
    }
}